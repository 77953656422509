import classNames from "classnames";
import { FormField } from "components/forms/FormField";
import { Tabs } from "components/Tabs";
import { BooleanField } from "components/forms/BooleanField";
import { ArrayField } from "react-hook-form";
import { Button, Input, ListGroup, ListGroupItem } from "reactstrap";
import { SelectField } from "components/forms/SelectField";
import { DateField, DateTimeField, TimeField } from "components/forms/DateTimeField";
import { AUTOCOMPLETE_URLS } from "utils/constants";
import { Autocomplete } from "components/autocompletes/Autocomplete";
import React from "react";
import { CheckPermissions } from "components/CheckPermissions";
import { useOptionsOrder } from "Traction/hooks/orders";

interface Props {
  control: any;
  register: any;
  errors: any;
  defaultValues?: any;
  editable?: boolean;
  getValues: any;
  fieldsExtra: Partial<ArrayField<Record<string, any>, "id">>[];
  removeExtra: (index?: number | number[] | undefined) => void;
  appendExtra: (
    value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
    shouldFocus?: boolean | undefined
  ) => void;
  fieldsPoints: Partial<ArrayField<Record<string, any>, "id">>[];
  removePoints: (index?: number | number[] | undefined) => void;
  appendPoints: (
    value: Partial<Record<string, any>> | Partial<Record<string, any>>[],
    shouldFocus?: boolean | undefined
  ) => void;
  options: any;
  watch: any;
}

export const OrderForm = ({
  control,
  register,
  errors,
  defaultValues,
  editable = true,
  getValues,
  fieldsExtra,
  appendExtra,
  removeExtra,
  fieldsPoints,
  appendPoints,
  removePoints,
  options,
  watch,
}: Props) => {
  const { data: optionsOrder } = useOptionsOrder();

  return optionsOrder ? (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }} >
        <div className="d-flex flex-column flex-lg-row" style={{ flex: 3 }} >
          <table className={classNames("table_col")}>
            <tbody>
              <tr>
                <td>
                  <b>Cliente</b>
                </td>
                <td>
                  <Autocomplete
                    disabled={!editable}
                    url={AUTOCOMPLETE_URLS.CLIENT}
                    control={control}
                    defaultValue={
                      defaultValues?.client_id ? defaultValues?.client_id : null
                    }
                    errorMessage={errors.client_id?.message}
                    name="client_id"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Data carico</b>
                </td>
                <td>
                  <DateField
                    defaultValue={defaultValues?.date ? defaultValues?.date : null}
                    disabled={true}
                    name={"date"}
                    errorMessage={errors.date?.message}
                    control={control} />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Prezzo manuale</b>
                </td>
                <td>
                  <FormField
                    defaultValue={
                      defaultValues?.value
                        ? defaultValues?.value
                        : null
                    }
                    type="number"
                    register={register}
                    disabled={!editable}
                    name={"value"}
                    errorMessage={errors.value?.message}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Descrizione fatturazione</b>
                </td>
                <td>
                  <FormField
                    defaultValue={
                      defaultValues?.invoice_notes
                        ? defaultValues?.invoice_notes
                        : null
                    }
                    type="text"
                    register={register}
                    disabled={!editable}
                    name={"invoice_notes"}
                    errorMessage={errors.invoice_notes?.message}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <table className={classNames("table_col")}>
            <tbody>
              <tr>
                <td>
                  <b>Tipo di trasporto</b>
                </td>
                <td>
                  <SelectField
                    disabled={!editable}
                    placeholder="Tipo di trasporto"
                    options={optionsOrder.required_transport.choices}
                    register={register}
                    errorMessage={errors.required_transport?.message}
                    name="required_transport"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>N. ordine</b>
                </td>
                <td>
                  <FormField
                    defaultValue={
                      defaultValues?.order_number
                        ? defaultValues?.order_number
                        : null
                    }
                    type={"text"}
                    register={register}
                    disabled={!editable}
                    name={"order_number"}
                    errorMessage={errors.order_number?.message}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <b>Note</b>
                </td>
                <td>
                  <FormField
                    defaultValue={
                      defaultValues?.order_number
                        ? defaultValues?.order_number
                        : null
                    }
                    type={"textarea"}
                    register={register}
                    disabled={!editable}
                    name={"notes"}
                    errorMessage={errors.notes?.message}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div >
        <div className={classNames("p-1")} style={{ flex: 2 }}>
          <div className={"d-flex flex-row justify-content-justify"}>
            <div>
              <h3>Extra</h3>
            </div>
            <div className={"justify-content-end"}>
              <CheckPermissions permissions={["rosina.change_order"]}>
                <Button
                  size="sm"
                  color="primary"
                  onClick={() =>
                    appendExtra({
                    })
                  }
                  style={{ padding: ".25rem .5rem" }}
                >
                  <span></span> + <span></span>
                </Button>
              </CheckPermissions>
            </div>
          </div>



          <ListGroup>
            {fieldsExtra.map((item, index) => {
              return (
                <ListGroupItem key={item.key} style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }}>

                  <table className={classNames("table-no-border")} style={{ width: "100%" }}>
                    <tbody>
                      <tr>
                        <td>
                          <b>N. </b>
                        </td>
                        <td>
                          <b>Descrizione </b>
                        </td>
                        <td>
                          <b>Valore € </b>
                        </td>
                        <td>
                          <b>Note </b>
                        </td>
                        <td>
                          <b> </b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{index}</h4>
                          <input
                            defaultValue={item.id}
                            type="hidden"
                            ref={register()}
                            name={`extras[${index}].id`}
                          />
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.extras?.[index]?.description
                                ? defaultValues?.extras?.[index]?.description
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`extras[${index}].description`}
                            errorMessage={errors.extras?.[index]?.description.message}
                          />
                        </td>
                        <td style={{ maxWidth: "80px" }}>
                          <FormField
                            defaultValue={
                              defaultValues?.extras?.[index]?.value
                                ? defaultValues?.extras?.[index]?.value
                                : null
                            }
                            type={"number"}
                            register={register}
                            disabled={!editable}
                            name={`extras[${index}].value`}
                            errorMessage={errors.extras?.[index]?.value.message}
                          />
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.extras?.[index]?.notes
                                ? defaultValues?.extras?.[index]?.notes
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`extras[${index}].notes`}
                            errorMessage={errors.extras?.[index]?.notes.message}
                          />
                        </td>
                        <td>
                          <CheckPermissions permissions={["rosina.change_order"]}>
                            <div className="d-flex flex-row justify-content-end">
                              <Button
                                type="button"
                                size="sm"
                                color="danger"
                                onClick={() => removeExtra(index)}
                                style={{ padding: ".25rem .5rem" }}
                              >
                                -
                              </Button>
                            </div>
                          </CheckPermissions>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </div>

      </div >
      <div className="p-1">
        <div className={"d-flex flex-row justify-content-justify"}>
          <div>
            <h3>Carico/scarichi</h3>
          </div>
          <div className={"justify-content-end"}>
            <CheckPermissions permissions={["rosina.change_order"]}>
              <Button
                size="sm"
                color="primary"
                className="mb-3"
                onClick={() =>
                  appendPoints({
                    driver_id: null,
                  })
                }
              >
                <span></span> + <span></span>
              </Button>
            </CheckPermissions>
          </div>
        </div>
        <ListGroup>
          {fieldsPoints.map((item, index) => {
            return (
              <ListGroupItem key={item.key}
                style={{ backgroundColor: index === 0 ? "rgba(255, 0, 0, 0.12)" : "rgba(0, 255, 0, 0.12" }}
              >
                <h3>{index === 0 ? "Carico" : "Scarico"}</h3>
                <input
                  defaultValue={item.id}
                  type="hidden"
                  ref={register()}
                  name={`orderpoints[${index}].id`}
                />
                <div
                  className="d-flex flex-column flex-lg-row justify-content-between"

                >

                  <hr />
                  <table className={classNames("table_col_4", "mb-2")}>
                    <tbody>
                      <tr>
                        <td>
                          <b>Luogo </b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.place
                                ? defaultValues?.orderpoints?.[index]?.place
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].place`}
                            errorMessage={errors.orderpoints?.[index]?.place.message}
                          />
                        </td>
                        <td>
                          <b>Provincia </b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.province
                                ? defaultValues?.orderpoints?.[index]?.province
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].province`}
                            errorMessage={errors.orderpoints?.[index]?.province.message}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Data</b>
                        </td>
                        <td>
                          <DateField
                            defaultValue={defaultValues?.orderpoints?.[index]?.date ? defaultValues?.orderpoints?.[index]?.date : null}
                            disabled={!editable}
                            name={`orderpoints[${index}].date`}
                            errorMessage={errors.orderpoints?.[index]?.date?.message}
                            control={control} />
                        </td>
                        <td>
                          <b>Slot (HH:MM)</b>
                        </td>
                        <td>
                          <TimeField
                            disabled={!editable}
                            name={`orderpoints[${index}].minutes`}
                            defaultValue={defaultValues?.orderpoints?.[index]?.minutes}
                            control={control}
                          />


                          {/*
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.minutes ? defaultValues?.orderpoints?.[index]?.minutes : null
                            }
                            type="number"
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].minutes`}
                            errorMessage={errors.orderpoints?.[index]?.minutes?.message}
                          />
                          */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className={classNames("table_col_4", "mb-2")}>
                    <tbody>
                      <tr>
                        <td>
                          <b>Autista</b>
                        </td>
                        <td>
                          <Autocomplete
                            url={AUTOCOMPLETE_URLS.DRIVER}
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.driver
                                ? defaultValues?.orderpoints?.[index]?.driver
                                : null
                            }
                            disabled={!editable}
                            name={`orderpoints[${index}].driver_id`}
                            control={control}
                            errorMessage={
                              errors.orderpoints?.[index]?.driver_id
                                ?.message
                            }
                          />
                        </td>
                        <td>
                          <b>Targa rimorchio</b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.plate
                                ? defaultValues?.orderpoints?.[index]?.plate
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].plate`}
                            errorMessage={errors.orderpoints?.[index]?.plate.message}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Numero documento</b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.document_number
                                ? defaultValues?.orderpoints?.[index]?.document_number
                                : null
                            }
                            type={"text"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].document_number`}
                            errorMessage={errors.orderpoints?.[index]?.document_number.message}
                          />
                        </td>
                        <td>
                          <b>Sgancio</b>
                        </td>
                        <td>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.unload_plate
                                ? defaultValues?.orderpoints?.[index]?.unload_plate
                                : null
                            }
                            type={"textarea"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].unload_plate`}
                            errorMessage={errors.orderpoints?.[index]?.unload_plate.message}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className={classNames("table_col")}>
                    <tbody>
                      <tr>
                        <td>
                          <b>Note</b>
                          <FormField
                            defaultValue={
                              defaultValues?.orderpoints?.[index]?.notes
                                ? defaultValues?.orderpoints?.[index]?.notes
                                : null
                            }
                            type={"textarea"}
                            register={register}
                            disabled={!editable}
                            name={`orderpoints[${index}].notes`}
                            errorMessage={errors.orderpoints?.[index]?.notes.message}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {index >= 1 && (
                  <CheckPermissions permissions={["rosina.change_order"]}>
                    <div className="d-flex flex-row justify-content-end">
                      <Button
                        type="button"
                        size="sm"
                        color="danger"
                        onClick={() => removePoints(index)}
                      >
                        Rimuovi
                      </Button>
                    </div>
                  </CheckPermissions>
                )}
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </div >

    </>
  ) : null;
};
